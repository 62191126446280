import React, {memo, Suspense, useEffect, useState} from 'react';
import {Device, useDevice} from 'shared/contexts/device';
import ResponsiveProvider, {mediaQueriesBuilder} from 'shared/contexts/device/responsive';
import Loader from 'job-board/shared/components/loader';
import {DESKTOP_MIN_SIZE} from 'job-board/shared/mediaQueries/mobileFirst';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import styles from './searchBar.scss';

const DesktopView = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.searchBar.desktopView' */ './components/desktopView'),
  'component.searchBar.desktopView'
);

const MobileView = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.searchBar.mobileView' */ './components/mobileView'),
  'component.searchBar.mobileView'
);

const SearchFilters = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.searchFilters' */ 'job-board/shared/components/searchFilters'),
  'component.searchFilters'
);

export type SearchBarProps = {
  baseLocation?: string;
  className?: string;
  dismissable?: boolean;
  isFixed?: boolean;
  isSticky?: boolean;
  isToggledOn?: boolean;
  onDismiss?: () => void;
  shouldAnimateSearchFilters?: boolean;
  shouldScrollToTop?: boolean;
  shouldShowSearchFilters?: boolean;
};

const SearchBar = ({
  baseLocation,
  className,
  dismissable,
  isFixed,
  isSticky,
  isToggledOn,
  onDismiss,
  shouldAnimateSearchFilters,
  shouldScrollToTop,
  shouldShowSearchFilters
}: SearchBarProps) => {
  const [isLazyLoaded, setIsLazyLoaded] = useState(isSticky || isToggledOn);
  const [device] = useDevice();

  const isMobile = device === Device.Mobile;

  useEffect(() => {
    if (isSticky || isToggledOn) {
      setIsLazyLoaded(true);
    }
  }, [isSticky, isToggledOn]);

  return (
    <>
      {isLazyLoaded && (
        <Suspense
          fallback={
            <Loader className={styles.loader} isFixedCenter={isMobile} isResponsive={isMobile} medium={!isMobile} />
          }
        >
          {isMobile ? (
            <MobileView baseLocation={baseLocation} className={className} isOpen={isToggledOn} onDismiss={onDismiss} />
          ) : (
            <DesktopView
              baseLocation={baseLocation}
              className={className}
              dismissable={dismissable}
              isFixed={isFixed}
              isSticky={isSticky}
              isToggledOn={isToggledOn}
              onDismiss={onDismiss}
              shouldAnimateSearchFilters={shouldAnimateSearchFilters}
              shouldScrollToTop={shouldScrollToTop}
              shouldShowSearchFilters={shouldShowSearchFilters}
            />
          )}
        </Suspense>
      )}
      {isMobile && shouldShowSearchFilters && (
        <Suspense fallback={null}>
          <SearchFilters isMobile={isMobile} />
        </Suspense>
      )}
    </>
  );
};

const mediaQueries = mediaQueriesBuilder()
  .setLargerDevice(Device.Mobile)
  .addQueryWithSpecificity(Device.Desktop, [`(min-width: ${DESKTOP_MIN_SIZE}px)`]);

const ResponsiveSearchBar = (props: SearchBarProps) => (
  <ResponsiveProvider mobileMediaQueries={mediaQueries}>
    <SearchBar {...props} />
  </ResponsiveProvider>
);

export default memo(ResponsiveSearchBar);
